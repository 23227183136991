import { useState, useEffect } from 'react'

import { Accordion } from 'react-bootstrap';
import { IoTrashOutline } from 'react-icons/io5';

import '../components/demo.css';
import LoginModule from '../components/glogin.js';
import {getLoginInfoFromCookie} from '../routes/utils';

const ServiceItem = ({ service, index, getDatasourceNameFromId, handleRemoveService }) => {
  const datasourceName = getDatasourceNameFromId(service['data_source_id']);

  return (
    <div className="row my-3 mx-3">
      <div className="col-md-3">
        <p>{service.name} ({datasourceName})</p>
      </div>
      <div className="col-md-4">
        {datasourceName === 'gcp' && <p><pre>{service.logs.query}</pre></p>}
        {datasourceName === 'aws' && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <p>Log Group Name: {service.logs['log_group_name']}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p><pre>{service.logs.query}</pre></p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-4">
        {datasourceName === 'gcp' && service.metrics && service.metrics.length > 0 && (
          <details>
            <summary>{service.metrics.length} metrics from {service.metrics[0].name.split('.')[0]}</summary>
            <div className="row">
              <div className="col-md-12">
                <p><strong>Product</strong></p>
                <p>{service.metrics[0].name.split('.')[0]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p><strong>Metrics</strong></p>
                <p>{service.metrics.map((metric) => {
                  let splits = metric.name.split('/');
                  return splits[splits.length - 1];
                }).join(", ")}</p>
              </div>
            </div>
          </details>
        )}
        {datasourceName === 'aws' && service.metrics && service.metrics.length > 0 && (
          <details>
            <summary>{service.metrics.length} metrics from {service.metrics[0].namespace}</summary>
            <div className="row my-3">
              <div className="col-md-12">
                <p><strong>Namespace</strong></p>
                <p>{service.metrics[0].namespace}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p><strong>Metrics</strong></p>
                <p>{service.metrics.map((metric) => metric.name).join(", ")}</p>
              </div>
            </div>
          </details>
        )}
      </div>
      <div className="col-md-1">
        <button onClick={() => handleRemoveService(index)} className="btn btn-secondary btn-sm">
          <IoTrashOutline />
        </button>
      </div>
    </div>
  );
};

const Configuration = (props) => {
  const [loginState, setLoginState] = useState(null);

  const [clientSystemId, setClientSystemId] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [dataSources, setDataSources] = useState([]);

  const [gcpDatasourceId, setGcpDatasourceId] = useState(null);
  const [awsDatasourceId, setAwsDatasourceId] = useState(null);

  const getDatasourceNameFromId = (datasourceId) => {
    let datasource = dataSources.find((ds) => ds['id'] === datasourceId);
    if (datasource){
      return datasource['data_source_type'];
    }
    return "";
  }

  const setUserDefinedSystemConfig = (userDefinedSystemConfig) => {
    if (userDefinedSystemConfig) {
      if (userDefinedSystemConfig['services']){
        setServices(userDefinedSystemConfig['services']);
      }
      if (userDefinedSystemConfig['other_log_sources']){
        setOtherLogSources(userDefinedSystemConfig['other_log_sources']);
      }
      if (userDefinedSystemConfig['runbook']){
        setRunbook(userDefinedSystemConfig['runbook']);
      } 
    }
  }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const [getConfigurationTimeoutId, setGetConfigurationTimeoutId] = useState(null);

  useEffect(() => {
    return () => {
      if (getConfigurationTimeoutId) {
        clearTimeout(getConfigurationTimeoutId);
      }
    }
  }, []);

  useEffect(() => {
    setCurrentlyEditingServiceDatasourceId(defaultDatasourceId());
  }, [dataSources]);

  const getConfiguration = async (retryCount=0) => {
    console.log("getConfiguration retryCount: ", retryCount);
    if (retryCount >= 5) {
      return;
    }

    if (!clientSystemId) {
      return;
    }

    var url = `${API_BASE_URL}/api/configure?client_system_id=${clientSystemId}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options);
    if (response.ok) {
      let response_data = await response.json();
      setDataSources(response_data['data_sources']);
      for (let datasource of response_data['data_sources']) {
        if (datasource['data_source_type'] === 'gcp') {
          setGcpDatasourceId(datasource['id']);
          if (!(datasource['data_kv'] && datasource['data_kv']['active_metrics'] && datasource['data_kv']['active_metrics'].length > 0)){
            setGetConfigurationTimeoutId(setTimeout(() => {
              getConfiguration(retryCount + 1);
            }, 5000));
          }
          testGcpConnection();
        }
        if (datasource['data_source_type'] === 'aws') {
          setAwsDatasourceId(datasource['id']);
          if (!(datasource['data_kv'] && datasource['data_kv']['active_metrics'] && datasource['data_kv']['active_metrics'].length > 0)){
            setGetConfigurationTimeoutId(setTimeout(() => {
              getConfiguration(retryCount + 1);
            }, 5000));
          }
          testAwsConnection();
        }
      }
      setUserDefinedSystemConfig(response_data['user_provided_system_config']);
    } else {
      setFailureMessage("Failed to fetch data");
    }
  }

  const getAwsMetricDefs = () => {
    let awsDatasource = dataSources.find((ds) => ds['data_source_type'] === 'aws');
    if (!awsDatasource){
      return [];
    }
    let data_kv = awsDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    if (!data_kv['active_metrics']){
      return [];
    }
    return data_kv['active_metrics'];
  }

  const getAwsLogGroupNameOptions = () => {
    let awsDatasource = dataSources.find((ds) => ds['data_source_type'] === 'aws');
    if (!awsDatasource){
      return [];
    }
    let data_kv = awsDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    if (!data_kv['log_group_names']){
      return [];
    }
    return data_kv['log_group_names'];
  }

  const getAwsMetricNamespaceOptions = () => {
    let allMetrics = getAwsMetricDefs();
    // get list of unique namespaces
    let namespaces = allMetrics.map((metric) => metric['Namespace']);
    let uniqueNamespaces = [...new Set(namespaces)];
    return uniqueNamespaces;
  }

  const getAwsMetricNameOptions = (namespace) => {
    let allMetrics = getAwsMetricDefs();
    let metricsForNamespace = allMetrics.filter((metric) => metric['Namespace'] === namespace);
    let metricNames = [...new Set(metricsForNamespace.map((metric) => metric['MetricName']))];
    return metricNames;
  }

  useEffect(() => {
    getConfiguration()
  }, [clientSystemId]);


  const [jiraDomain, setJiraDomain] = useState("");
  const [jiraEmail, setJiraEmail] = useState("");
  const [jiraToken, setJiraToken] = useState("");
  const [jiraProjectKeys, setJiraProjectKeys] = useState("");


  const addDataSource = (url, payload) => {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      //console.log('Success:', data);
      getConfiguration();
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });
  }

  const connectJira = () => {
    let payload = {
      'client_system_id': clientSystemId,
      'data_source': {
        'type': 'jira',
        'jira_domain': jiraDomain,
        'jira_email': jiraEmail,
        'jira_api_token': jiraToken,
        'jira_project_keys': jiraProjectKeys.split(',')
      }
    }
    const url = `${API_BASE_URL}/api/configure/data_sources`;
    addDataSource(url, payload);
  }

  const [gcpCreds, setGcpCreds] = useState(null);
  const connectGcp = async () => {
    setIsGcpLoading(true);
    try {
      const text = await gcpCreds.text();
      let credentials_json = JSON.parse(text);
      let payload = {
        'client_system_id': clientSystemId,
        'data_source': {
          'type': 'gcp',
          'credentials_json': credentials_json
        }
      }

      const url = `${API_BASE_URL}/api/configure/data_sources`;
      await addDataSource(url, payload);
      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));

    } catch (error) {
      console.error('Error connecting GCP:', error);
    } finally {
      setIsGcpLoading(false);
    }
  }

  // AWS Config
  const [awsAccessKeyId, setAwsAccessKeyId] = useState("");
  const [awsSecretAccessKey, setAwsSecretAccessKey] = useState("");
  const [awsRegion, setAwsRegion] = useState("");
  const connectAws = async () => {
    setIsAwsLoading(true);
    // setAwsConnectionStatus(null);
    // setAwsConnectionError(null);

    let payload = {
      'client_system_id': clientSystemId,
      'data_source': {
        'type': 'aws',
        'aws_access_key_id': awsAccessKeyId,
        'aws_secret_access_key': awsSecretAccessKey,
        'aws_region': awsRegion
      }
    }

    const url = `${API_BASE_URL}/api/configure/data_sources`;
    try {
      await addDataSource(url, payload);

      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));

      // setAwsConnectionStatus('success');
    } catch (error) {
      // setAwsConnectionStatus('error');
      // setAwsConnectionError('An error occurred while connecting to AWS. Please check your credentials.');
      console.error('Error connecting to AWS:', error);
    } finally {
      setIsAwsLoading(false);
    }
  }

  const [currentlyEditingAwsMetricNamespace, setCurrentlyEditingAwsMetricNamespace] = useState("");
  const [currentlyEditingAwsMetricNames, setCurrentlyEditingAwsMetricNames] = useState([]);
  const [currentlyEditingAwsLogGroupName, setCurrentlyEditingAwsLogGroupName] = useState("");

  const handleAwsMetricNameChanges = (e) => {
    let selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setCurrentlyEditingAwsMetricNames(selectedOptions);
  };


  const defaultDatasourceId = () => {
    return dataSources.length === 1 ? dataSources[0]['id'] : "";
  }

  const [isAwsServiceSaving, setIsAwsServiceSaving] = useState(false);
  const handleSaveAwsService = async () => {
    setIsAwsServiceSaving(true);

    try {
      let updatedServices = [...services, {
        'data_source_id': awsDatasourceId,
        name: currentlyEditingServiceName,
        logs: {
          query: currentlyEditingServiceQuery,
          'log_group_name': currentlyEditingAwsLogGroupName,
        },
        metrics: currentlyEditingAwsMetricNames.map((metric) => {
          return {
            'namespace': currentlyEditingAwsMetricNamespace,
            'name': metric
          };
        }),
      }];

      await handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
      setCurrentlyEditingServiceDatasourceId(defaultDatasourceId());
      setCurrentlyEditingServiceName("");
      setCurrentlyEditingServiceQuery("");
      setCurrentlyEditingAwsMetricNamespace("");
      setCurrentlyEditingAwsMetricNames([]);
    } catch (error) {
      console.error("Error saving AWS service:", error);
    } finally {
      setIsAwsServiceSaving(false);
    }
  }

  const [currentlyEditingServiceDatasourceId, setCurrentlyEditingServiceDatasourceId] = useState(defaultDatasourceId());
  const [currentlyEditingServiceName, setCurrentlyEditingServiceName] = useState("");
  const [currentlyEditingServiceQuery, setCurrentlyEditingServiceQuery] = useState("");

  // GCP Metrics
  const [currentlyEditingGcpMetricProduct, setCurrentlyEditingGcpMetricProduct] = useState("");
  const [currentlyEditingGcpMetricNames, setCurrentlyEditingGcpMetricNames] = useState([]);

  const handleGcpMetricNameChanges = (e) => {
    let selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setCurrentlyEditingGcpMetricNames(selectedOptions);
  }

  const getGcpMetricDefs = () => {
    let gcpDatasource = dataSources.find((ds) => ds['data_source_type'] === 'gcp');
    if (!gcpDatasource){
      return [];
    }
    let data_kv = gcpDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    return data_kv['active_metrics'];
  }

  const getGcpMetricProductOptions = () => {
    let allMetrics = getGcpMetricDefs();
    // get list of unique products
    let products = allMetrics.map((metric) => metric['name'].split('.')[0]);
    let uniqueProducts = [...new Set(products)];
    return uniqueProducts;
  }

  const getGcpMetricNameOptions = (product) => {
    let allMetrics = getGcpMetricDefs();
    let metricsForNamespace = allMetrics.filter((metric) => metric['name'].split('.')[0] === product);
    let metricNames = [...new Set(metricsForNamespace.map((metric) => metric['name']))];
    return metricNames;
  }

  const findGcpMetricByName = (metricName) => {
    let allMetrics = getGcpMetricDefs();
    return allMetrics.find((metric) => metric['name'] === metricName);
  }

  const [services, setServices] = useState([]);


  // const handleSaveService = () => {
  //   if (currentlyEditingServiceName) {
  //     let updatedServices = [...services, {
  //       'data_source_id': gcpDatasourceId,
  //       name: currentlyEditingServiceName,
  //       logs: {
  //         query: currentlyEditingServiceQuery
  //       },
  //       metrics: {
  //         dashboard: currentlyEditingServiceMetricsDashboard
  //       }
  //     }];
  //     handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
  //     setCurrentlyEditingServiceName("");
  //     setCurrentlyEditingServiceQuery("");
  //     setCurrentlyEditingServiceMetricsDashboard("");
  //   }
  // }

  const handleRemoveService = (index) => {
    let updatedServices = services.filter((_, i) => i !== index);
    handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
  }

  
  const [isSavingGcpService, setIsSavingGcpService] = useState(false);

  const handleSaveGcpService = async () => {
    setIsSavingGcpService(true);
    try {
      let updatedServices = [...services, {
        'data_source_id': gcpDatasourceId,
        name: currentlyEditingServiceName,
        logs: {
          query: currentlyEditingServiceQuery
        },
        metrics: currentlyEditingGcpMetricNames.map((metric) => {
          return findGcpMetricByName(metric);
        }),
      }];
      await handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
      setCurrentlyEditingServiceName("");
      setCurrentlyEditingServiceQuery("");
      setCurrentlyEditingGcpMetricProduct("");
      setCurrentlyEditingGcpMetricNames([]);
    } catch (error) {
      console.error("Error saving GCP service:", error);
      // Optionally, set an error state here to display to the user
    } finally {
      setIsSavingGcpService(false);
    }
  }

  const [currentlyEditingOtherLogSourceDatasourceId, setCurrentlyEditingOtherLogSourceDatasourceId] = useState("");
  const [currentlyEditingOtherLogSourceDescription, setCurrentlyEditingOtherLogSourceDescription] = useState("");
  const [currentlyEditingOtherLogSourceQuery, setCurrentlyEditingOtherLogSourceQuery] = useState("");

  const [otherLogSources, setOtherLogSources] = useState([]);

  // const handleSaveOtherLogSource = () => {
  //   let updatedOtherLogSources = [...otherLogSources, {
  //     'data_source_id': currentlyEditingOtherLogSourceDatasourceId,
  //     name: currentlyEditingOtherLogSourceDescription,
  //     query: currentlyEditingOtherLogSourceQuery
  //   }];
  //   setCurrentlyEditingOtherLogSourceDescription("");
  //   setCurrentlyEditingOtherLogSourceQuery("");
  //   handleUserDefinedSytemConfigSave(services, updatedOtherLogSources, runbook);
  // }

  const serviceNamePlaceholderText = 'User Service - responsible for user signup flow'
  const servicePlaceholderQuery = 'resource.type="cloud_run_revision" AND resource.labels.service_name="user-service"';

  const runbookPlaceholderText = `- For login related issues, we look at logs from the user service.\n- For database issues, escalate to platforms team.
  `;

  const [runbook, setRunbook] = useState("");
  const [isRunbookSaving, setIsRunbookSaving] = useState(false);
  const handleRunbookSave = async () => {
    setIsRunbookSaving(true);
    await handleUserDefinedSytemConfigSave(services, otherLogSources, runbook);
    setIsRunbookSaving(false);
  }

  const handleUserDefinedSytemConfigSave = (updatedServices, updatedOtherLogSources, updatedRunbook) => {
    return new Promise((resolve, reject) => {
      let payload = {
        'client_system_id': clientSystemId,
        'services': updatedServices,
        'other_log_sources': updatedOtherLogSources,
        'runbook': updatedRunbook
      }
      fetch(`${API_BASE_URL}/api/configure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      }).then(response => response.json())
        .then(data => {
          //console.log('Success:', data);
          getConfiguration();
          resolve(data);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        });
    });
  }

  const saveConfiguration = () => {
    window.location.href = "/dashboard";
  }

  const getNextButtonText = () => {
    return "Go to dashboard!"
  }

  const [gcpConnectionStatus, setGcpConnectionStatus] = useState(null);
  const [awsConnectionStatus, setAwsConnectionStatus] = useState(null);

  const [gcpConnectionError, setGcpConnectionError] = useState(null);
  const [awsConnectionError, setAwsConnectionError] = useState(null);

  const [isGcpLoading, setIsGcpLoading] = useState(false);
  const [isAwsLoading, setIsAwsLoading] = useState(false);

  // Modify testGcpConnection to accept clientSystemId as a parameter
  const testGcpConnection = async () => {
    setGcpConnectionStatus(null);
    setGcpConnectionError(null);

    const url = `${API_BASE_URL}/api/test_gcp_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setGcpConnectionStatus(null);
      setGcpConnectionError(null);
      return;
    }
    //console.log("Testing GCP connection for client system id:", clientSystemId);
    const payload = {
      client_system_id: clientSystemId
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();

      if (response.ok) {
        setGcpConnectionStatus('success');
        //console.log('Connection test successful:', data);
      } else {
        if (data.error && data.error.startWith('GCP data source not found')){
          setGcpConnectionStatus(null);
          setGcpConnectionError(null);
        } else {
          setGcpConnectionStatus('error');
          setGcpConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setGcpConnectionStatus('error');
      setGcpConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  const testAwsConnection = async () => {
    setAwsConnectionStatus(null);
    setAwsConnectionError(null);
  
    const url = `${API_BASE_URL}/api/test_aws_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setAwsConnectionStatus(null);
      setAwsConnectionError(null);
      return;
    }
  
    const payload = {
      client_system_id: clientSystemId
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setAwsConnectionStatus('success');
      } else {
        if (data.error && data.error.startsWith('AWS data source not found')) {
          setAwsConnectionStatus(null);
          setAwsConnectionError(null);
        } else {
          setAwsConnectionStatus('error');
          setAwsConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setAwsConnectionStatus('error');
      setAwsConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  // Update connectAndTestGcp to use the current clientSystemId state
  // const connectAndTestGcp = async () => {
  //   setGcpConnectionStatus(null);
  //   setGcpConnectionError(null);
  //   setIsGcpLoading(true);

  //   try {
  //     await connectGcp();
  //     await new Promise(resolve => setTimeout(resolve, 2000)); // 2 second delay
  //     await testGcpConnection();
  //   } catch (error) {
  //     setGcpConnectionStatus('error');
  //     setGcpConnectionError('Failed to connect to GCP');
  //     console.error('Error connecting to GCP:', error);
  //   } finally {
  //     setIsGcpLoading(false);
  //   }
  // };

  const getGcpProjectId = () => {
    const gcpDataSource = dataSources.find(ds => ds.data_source_type === 'gcp');
    if (gcpDataSource && gcpDataSource.configuration_kv && gcpDataSource.configuration_kv.credentials_json) {
      return gcpDataSource.configuration_kv.credentials_json.project_id;
    }
    return null;
  };

  const buttonStyle = {
    backgroundColor: '#5935c9',
    borderColor: '#5935c9',
    color: 'white',
    transition: 'background-color 0.3s, color 0.3s'
  };

  const selectOnChange = (e) => {
    setCurrentlyEditingServiceDatasourceId(e.target.value);
  }


  const loginCallback = (data) => {
    setClientSystemId(data.user.workspaces[0].client_system_id);
    setLoginState('logged_in');
  }

  const loginFailureCallback = () => {
    setLoginState('not_logged_in');
  }

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  if (!loginState){
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in') {
    return (<LoginModule onSuccessCallback={loginCallback} />);
  }

  return (
    <div class="container">
      <div class="row my-3">
        <div class="col-md-12">
          <h4>👋. Welcome, let's get you started!</h4>
        </div>
      </div>
      <div class="row my-3">
        <h5 class="my-4">Step 1. Let's start by connecting your observability tools</h5>
        <Accordion alwaysOpen>
          <Accordion.Item key={0} eventKey={"0"}>
            <Accordion.Header>
              Connect Google Cloud Monitoring
              {gcpConnectionStatus && (
                <span className={`badge ms-2 ${gcpConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {gcpConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body>
              {gcpConnectionStatus === 'success' && getGcpProjectId() && (
                <div className="alert alert-success mb-3">
                  Connected to GCP Project: <strong>{getGcpProjectId()}</strong>
                </div>
              )}
              <div class="mb-3">
                <label for="formFile" class="form-label">Credentials JSON File</label>
                <input class="form-control" type="file" id="formFile" onChange={(e) => setGcpCreds(e.target.files[0])} />
              </div>
              <button 
                className="btn btn-primary custom-btn" 
                disabled={!gcpCreds || isGcpLoading} 
                onClick={connectGcp}
                style={buttonStyle}
              >
                {isGcpLoading ? (
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                ) : null}
                Connect GCP
              </button>
              {gcpConnectionError && (
                <div className="alert alert-danger mt-2" role="alert">
                  {gcpConnectionError}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item key={1} eventKey={"1"}>
            <Accordion.Header>
              Connect AWS CloudWatch
              {awsConnectionStatus && (
                <span className={`badge ms-2 ${awsConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {awsConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div class="mb-3">
                <label for="awsAccessKeyId">AWS Access Key ID</label>
                <input type="text" class="form-control my-3" value={awsAccessKeyId} id="awsAccessKeyId" placeholder="" onChange={(e) => setAwsAccessKeyId(e.target.value)} />
                <label for="awsSecretAccessKey">AWS Secret Access Key</label>
                <input type="password" class="form-control my-3" value={awsSecretAccessKey} id="awsSecretAccessKey" placeholder="" onChange={(e) => setAwsSecretAccessKey(e.target.value)} />
                <label for="awsRegion">AWS Region</label>
                <input type="text" class="form-control my-3" value={awsRegion} id="awsRegion" placeholder="" onChange={(e) => setAwsRegion(e.target.value)} />
              </div>
              <button 
                className="btn btn-primary custom-btn" 
                style={buttonStyle} 
                disabled={awsAccessKeyId.length === 0 || awsSecretAccessKey.length === 0 || awsRegion.length === 0 || isAwsLoading} 
                onClick={connectAws}
              >
                {isAwsLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  </>
                ) : null}
                Connect AWS
              </button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>


      <div class="row my-3">
        <div class="col-md-12">
          <h5 class="my-4">Step 2. Tell us a little bit about how you debug issues in this project.</h5>
          <p>Imagine you are onboarding a new engineer to this project. No need to be very detailed - we are looking for a few hints to get started.</p>
        </div>
      </div>
      <Accordion alwaysOpen>
        <Accordion.Item key={0} eventKey={"0"}>
          <Accordion.Header>What services do you investigate?</Accordion.Header>
          <Accordion.Body>
            <div class="row my-3">
              <div class="col-md-3"><p><strong>Service name</strong></p></div>
              <div class="col-md-4"><p><strong>Logs Query</strong></p></div>
              <div class="col-md-4"><p><strong>Metrics</strong></p></div>
              <div class="col-md-1"></div>
            </div>
            {services.map((service, index) => (
              <ServiceItem
                key={index}
                service={service}
                index={index}
                getDatasourceNameFromId={getDatasourceNameFromId}
                handleRemoveService={handleRemoveService}
              />
            ))}
            <div class="form-group">
              {dataSources.length > 0 ? (
                <div class="row my-3">
                  <div class="col-md-3">
                    <select class="form-select my-3" value={currentlyEditingServiceDatasourceId} onChange={selectOnChange}>
                      <option selected>Select datasource</option>
                      {gcpDatasourceId && <option value={gcpDatasourceId}>GCP</option>}
                      {awsDatasourceId && <option value={awsDatasourceId}>AWS</option>}
                    </select>
                  </div>
                </div>
              ) : (
                <p className="text-warning fw-bold border border-warning rounded p-2 d-inline-block">
                  <small>⚠️ Please connect to a data source first</small>
                </p>
              )}
              {currentlyEditingServiceDatasourceId == gcpDatasourceId &&
              <div>
                <div class="row my-3">
                  <div class="col-md-3">
                    <textarea class="form-control my-3" rows="10" placeholder={serviceNamePlaceholderText} value={currentlyEditingServiceName} onChange={(e) => setCurrentlyEditingServiceName(e.target.value)}></textarea>
                  </div>
                  <div class="col-md-4">
                    <textarea class="form-control my-3" id="runbookQuery" rows="10" placeholder={servicePlaceholderQuery} value={currentlyEditingServiceQuery} onChange={(e) => setCurrentlyEditingServiceQuery(e.target.value)}></textarea>
                  </div>
                  <div class="col-md-4">
                    <div clas="row">
                      <div class="col-md-12">
                        <select class="form-select my-3" id="gcpMetricProductName" value={currentlyEditingGcpMetricProduct} onChange={(e) => setCurrentlyEditingGcpMetricProduct(e.target.value)}>
                          <option selected>Select GCP Product</option>
                          {getGcpMetricProductOptions().map((product) => {
                            return <option value={product}>{product}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <select multiple={true} class="form-select my-3" id="gcpMetricName" value={currentlyEditingGcpMetricNames} onChange={handleGcpMetricNameChanges}>
                          <option selected>Select metric</option>
                          {getGcpMetricNameOptions(currentlyEditingGcpMetricProduct).map((metric) => {
                            return <option value={metric}>{metric}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button 
                disabled={(services.length==0 && currentlyEditingServiceName.length === 0) || isSavingGcpService} 
                onClick={handleSaveGcpService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                {isSavingGcpService ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
              </button>
              </div>}
              {currentlyEditingServiceDatasourceId == awsDatasourceId &&
              <div>
                <div class="row my-3">
                  <div class="col-md-3">
                    <textarea id="awsServiceName" class="form-control my-3" rows="10" placeholder={serviceNamePlaceholderText} value={currentlyEditingServiceName} onChange={(e) => setCurrentlyEditingServiceName(e.target.value)}></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-12">
                        <select class="form-select my-3" id="awsLogGroupName" value={currentlyEditingAwsLogGroupName} onChange={(e) => setCurrentlyEditingAwsLogGroupName(e.target.value)}>
                          <option selected>Select log group</option>
                          {getAwsLogGroupNameOptions().map((logGroupName) => {
                            return <option value={logGroupName}>{logGroupName}</option>;
                            })}
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <textarea id="awsServiceLogsQuery" class="form-control my-3" rows="10" placeholder={servicePlaceholderQuery} value={currentlyEditingServiceQuery} onChange={(e) => setCurrentlyEditingServiceQuery(e.target.value)}></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div clas="row">
                      <div class="col-md-12">
                        <select class="form-select my-3" id="awsMetricNamespace" value={currentlyEditingAwsMetricNamespace} onChange={(e) => setCurrentlyEditingAwsMetricNamespace(e.target.value)}>
                          <option selected>Select namespace</option>
                          {getAwsMetricNamespaceOptions().map((namespace) => {
                            return <option value={namespace}>{namespace}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <select multiple={true} class="form-select my-3" id="awsMetricName" value={currentlyEditingAwsMetricNames} onChange={handleAwsMetricNameChanges}>
                          <option selected>Select metric</option>
                          {getAwsMetricNameOptions(currentlyEditingAwsMetricNamespace).map((metric) => {
                            return <option value={metric}>{metric}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button 
                disabled={(services.length==0 && currentlyEditingServiceName.length === 0)} 
                onClick={handleSaveAwsService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                Save
              </button> */}
                <button 
                disabled={(services.length === 0 && currentlyEditingServiceName.length === 0) || isAwsServiceSaving} 
                onClick={handleSaveAwsService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
                >
                {isAwsServiceSaving ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
                </button>
              </div>}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* <Accordion.Item key={1} eventKey={"1"}>
          <Accordion.Header>Any other logs that you look at?</Accordion.Header>
          <Accordion.Body>
            <div class="row my-3">
              <div class="col-md-4">
                <p><strong>Log Name</strong></p>
              </div>
              <div class="col-md-4">
                <p><strong>Logs Query</strong></p>
              </div>
            </div>
            {otherLogSources.map((logSource, indx) => {
            return <div class="row my-3 mx-3">
              <div class="col-md-4">
                <p>{logSource.description}</p>
              </div>
              <div class="col-md-4">
                <p><pre>{logSource.query}</pre></p>
              </div>
            </div>
            })}
            <div class="form-group">
              <div class="row my-3">
                <div class="col-md-4">
                  <textarea class="form-control my-3" rows="10" value={currentlyEditingOtherLogSourceDescription} onChange={(e) => setCurrentlyEditingOtherLogSourceDescription(e.target.value)}></textarea>
                </div>
                <div class="col-md-4">
                  <textarea class="form-control my-3" id="runbookQuery" rows="10" value={currentlyEditingOtherLogSourceQuery} onChange={(e) => setCurrentlyEditingOtherLogSourceQuery(e.target.value)}></textarea>
                </div>
              </div>
              <button disabled={currentlyEditingOtherLogSourceDescription.length === 0 || !gcpDatasourceId} onClick={handleSaveOtherLogSource} class="btn btn-primary">Add Logs Source</button>
            </div>
          </Accordion.Body>
        </Accordion.Item> */}

        <Accordion.Item key={2} eventKey={"2"}>
          <Accordion.Header>Any other instructions/playbook?</Accordion.Header>
          <Accordion.Body>
            <div class="form-group">
              <div class="row my-3">
                <div class="col-md-12">
                  <textarea class="form-control my-3" rows="10" placeholder={runbookPlaceholderText} value={runbook} onChange={(e) => setRunbook(e.target.value)}></textarea>
                </div>
              </div>
              <button 
                disabled={runbook.length === 0} 
                onClick={handleRunbookSave} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                {isRunbookSaving ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save runbook'
                )}
              </button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div class="row my-3">
        <div class="col-md-12">
          <h5 class="my-4">[Optional] Step 3. Connect your JIRA to have Relvy debug your JIRA issues here</h5>
          <Accordion alwaysOpen>
            <Accordion.Item key={0} eventKey={"0"}>
              <Accordion.Header>Connect JIRA</Accordion.Header>
              <Accordion.Body>
                <label for="jiraDomain">Enter your JIRA domain</label>
                <input type="text" class="form-control my-3" value={jiraDomain} id="jiraDomain" placeholder="your-domain.atlassian.net" onChange={(e) => setJiraDomain(e.target.value)} />
                <label for="jiraEmail">Enter your JIRA email</label>
                <input type="text" class="form-control my-3" value={jiraEmail} id="jiraEmail" placeholder="" onChange={(e) => setJiraEmail(e.target.value)} />
                <label for="jiraToken">Enter your JIRA API token</label>
                <input type="text" class="form-control my-3" value={jiraToken} id="jiraToken" placeholder="" onChange={(e) => setJiraToken(e.target.value)} />
                <label for="jiraToken">Project keys, comma separated</label>
                <input type="text" class="form-control my-3" value={jiraProjectKeys} id="jiraProjectKeys" placeholder="" onChange={(e) => setJiraProjectKeys(e.target.value)} />
                <button class="btn btn-primary custom-btn" style={buttonStyle} onClick={connectJira}>Connect JIRA</button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <div class="row my-3">
        <div class="col-sm-12 col-md-4 col-md-offset-8">
          <button 
            disabled={dataSources.length === 0 || services.length === 0} 
            onClick={saveConfiguration} 
            class="btn btn-primary custom-btn"
            style={{
              backgroundColor: '#5935c9',
              borderColor: '#5935c9',
              color: 'white',
              transition: 'background-color 0.3s, color 0.3s'
            }}
          >
            {getNextButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Configuration;
