import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Dashboard from './routes/dashboard';
import DashboardV2 from './routes/dashboard_v2';
import ExpertSteps from './routes/expert_steps';
import Sandbox from './routes/sandbox'
import IssueViewer from './routes/issue_viewer';
import IssueAnalysisViewer from './routes/issue_analysis_viewer';
import DatasetViewer from './routes/dataset_viewer';
import CreateIncident from './routes/create_incident';
import IssueDetailsPage from './routes/issue_details';
import IssuesList from './routes/issue_list'
import Configuration from './routes/configuration'
import Landing from './routes/landing'
import { GoogleOAuthProvider } from '@react-oauth/google';


function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
      <div className="container">
        <a className="navbar-brand fw-bold" target="_blank" rel="noopener noreferrer" href="https://www.relvy.ai">
          <img alt="Relvy" height="50px" src="/RelvyAI_FullLogo_Cropped.png" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i className="bi-list"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li className="nav-item">
              <a className="nav-link me-lg-3 btn" target="_blank" rel="noopener noreferrer" href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij">Get in Touch</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/dashboard",
    element: <DashboardV2 />,
  },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   path: "/issue_list",
  //   element: <IssuesList />,
  // },
  {
    path: "/issues/:issueId/expert_steps",
    element: <ExpertSteps />,
  },
  // {
  //   path: "/sandbox/create",
  //   element: <CreateIncident />,
  // },
  // {
  //   path: "/admin/dataset_viewer",
  //   element: <DatasetViewer />,
  // },
  // {
  //   path: "/admin/issue_viewer",
  //   element: <IssueViewer />,
  // },
  // {
  //   path: "/admin/issue_analysis_viewer",
  //   element: <IssueAnalysisViewer />,
  // },
  // {
  //   path: "/issue_details",
  //   element: <IssueDetailsPage />,
  // },
  {
    path: "/configure",
    element: <Configuration />,
  },
]);

function RelvyApp() {
  return (
    <>
      <Navbar />
      <section style={{ paddingTop: '6rem' }}>
        <RouterProvider router={router} />
      </section>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="415844705945-j6o5aomfu4jmqgg86d2ehg4p4905u5ia.apps.googleusercontent.com">
      <RelvyApp />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
