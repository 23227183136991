import { createRef, useEffect, useMemo, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Spinner, Tabs, Tab } from 'react-bootstrap';


import {CustomPlaceholder, ParagraphPlaceholder} from '../routes/issue_details_components';
import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from '../routes/issue_details_components';
import {createDisplayStepFromLogAnalysisData, createDisplayStepFromMetricsAnalysisData} from '../routes/utils';

import MetricView from './metrics';
import LogsView from './logs';

import './demo.css';

const DetailsTab = ({ issue, onShowModal }) => (
  <>
    <DescriptionCard details={issue.details} />
    <AttachmentsCard 
      relevantFiles={issue.relevant_files}
      services={issue.system?.services}
      relevantFileData={issue.relevant_file_data}
      onShowModal={onShowModal}
    />
    <CommentsCard />
  </>
);

const AnalysisTab = ({ issue, analysisData, onUserPrompt, onInterrupt }) => (
  analysisData ? (
    <div>
      <AnalysisDataView issue={issue} analysisData={analysisData} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} />
    </div>
  ) : (
    <div className="text-center py-4">
      <Spinner animation="border" role="status" variant="purple">
        <span className="visually-hidden">Loading analysis...</span>
      </Spinner>
    </div>
  )
);

const DescriptionCard = ({ details }) => (
  <Card className="mb-3 description-card">
    <Card.Body>
      <Card.Title>Description</Card.Title>
      <Card.Text>{details || <ParagraphPlaceholder />}</Card.Text>
    </Card.Body>
  </Card>
);

const AttachmentsCard = ({ relevantFiles, services, onShowModal }) => {
  const relevantDataByServices = () => {
    if (!relevantFiles || !services) {
      return [];
    }

    let relevantData = new Map();
    services.forEach((service) => {
      relevantFiles.forEach((fileName) => {
        if (fileName.startsWith(service.name)) {
          if (!relevantData.has(service.name)) {
            relevantData.set(service.name, []);
          }
          relevantData.get(service.name).push(fileName);
        }
      });
    });
    return Array.from(relevantData, ([service, files]) => [service, files]);
  };

  return (
    <Card className="mb-3 attachments-card">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center mb-3">
          See logs and metrics
        </Card.Title>
        {!relevantFiles ? (
          <div className="text-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          relevantDataByServices().map(([service, files]) => (
            <div key={service} className="mb-3">
              <h6>Service Name: {service}</h6>
              <div className="d-flex flex-wrap gap-3">
                {files.map((fileName) => (
                  <Attachment
                    key={fileName}
                    fileName={fileName}
                    onClick={() => onShowModal(fileName)}
                  />
                ))}
              </div>
            </div>
          ))
        )}
      </Card.Body>
    </Card>
  );
};


const LogAnalysisStepView = (props) => {
  let stepTitle = `Analyzing logs from service '${props.analysisStepData['file_analyzed']}'`;
  let stepTitleComponents = {
    'action': 'logs',
    'service': props.analysisStepData['file_analyzed'],
    'stepIndx': props.stepIndx,
  };
  return (
		<LogsView 
			logData={props.analysisStepData.observation.logLines} 
			highlight={props.analysisStepData.observation.highlight} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			query={props.analysisStepData.query} 
			stepPostscript={props.analysisStepData.observation.relevance} 
		/>
  );
};

const MetricAnalysisStepView = (props) => {
  let stepTitle = `Analyzing metric '${props.analysisStepData['metric_analyzed']}' from service '${props.analysisStepData['service_analyzed']}'`;
  let metricData = props.analysisStepData.metric ? [props.analysisStepData.metric] : [];
  let stepTitleComponents = {
    'action': 'metrics',
    'metric': props.analysisStepData['metric_analyzed'],
    'service': props.analysisStepData['service_analyzed'],
    'stepIndx': props.stepIndx,
  };

  return (
		<MetricView 
			metricData={metricData} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			stepPostscript={props.analysisStepData.summary} 
			query={props.analysisStepData.query} 
		/>
  );
};

const AnalysisSteps = (props) => {
  const getStepTitle = (step, index) => {
    if (step['step_type'] === 'ongoing') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-ongoing">Currently running</span>: {step['currentNextStep']}
        </>
      );
    } else if (step['step_type'] === 'user_input') {
      return (
        <>
          <span className="highlight-logs">User Suggestion</span>: 
          <span className="user-message">{step['message']}</span>
        </>
      );
    } else if (step['step_type'] === 'log_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">logs</span>
          {' '}
          from service
          {' '}
          <code className="service-name">{step['file_analyzed']}</code>
        </>
      );
    } else {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">metric</span>
          {' '}
          <code className="service-name">{step['metric_analyzed']}</code>
          {' '}
          from service
          {' '}
          <code className="service-name">{step['service_analyzed']}</code>
        </>
      );
    }
  };

  const scrollRef = useMemo(() => createRef(), []);

  useEffect(() => {
    if (props.analysisData.stillRunning && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Card className="my-3 analysis-steps">
      <Card.Body>
        <Card.Title>Step-by-Step Root Cause Analysis</Card.Title>
        {props.analysisData && props.analysisData.analysisSteps.length > 0 && (
        <Accordion alwaysOpen>
          {props.analysisData.analysisSteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
              {getStepTitle(step, index)}
                {/* Step {index + 1}: {step['step_type'] === 'log_analysis' ? 'Log Analysis' : 'Metric Analysis'} */}
              </Accordion.Header>
              <Accordion.Body>
                {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                {step['step_type'] === 'metrics_analysis' && <MetricAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        ) 
        }
      </Card.Body>
      <div ref={scrollRef} />
    </Card>
  );
};


const AnalysisDataView = (props) => {
  const isDataAvailable = (props.analysisData && props.analysisData.rootCause) || (props.analysisData && props.analysisData.possibleRootCauseSoFar);
  const [userHint, setUserHint] = useState('');
  const [isInterrupting, setIsInterrupting] = useState(false);

  useEffect(() => {
    if (props.analysisData && !props.analysisData.stillRunning) {
      setIsInterrupting(false);
    }
  }, [props.analysisData]);

  const handleInterrupt = () => {
    setIsInterrupting(true);
    props.onInterrupt().then(() => {
      //setIsInterrupting(false);
    }).catch(() => {
      setIsInterrupting(false);
    });
  };

  const [isPrompting, setIsPrompting] = useState(false);
  const handlePrompt = () => {
    setIsPrompting(true);
    const result = props.onUserPrompt(userHint);
    Promise.resolve(result)
      .then(() => {
        setIsPrompting(false);
        setUserHint("");
      })
      .catch((error) => {
        console.error("Error in onUserPrompt:", error);
        setIsPrompting(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handlePrompt();
    }
  };

  return (<div>
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Description</Card.Title>
        <Card.Text>{props.issue.summary || '...'}</Card.Text>
        {isDataAvailable ? (
        <>
        {props.analysisData && props.analysisData.rootCause && (
          <>
          <Card.Title>Possible Root Cause</Card.Title>
          <Card.Text>{props.analysisData.rootCause}</Card.Text>
          {props.analysisData.nextStep && props.analysisData.nextStep !== "root_cause" && (
          <div className="mt-3">
            <Card.Title>Recommended Action</Card.Title>
            <p>{props.analysisData.nextStep}</p>
            <p>{props.analysisData.nextStepReason}</p>
          </div>
          )}
          </>)}
         {props.analysisData && !props.analysisData.rootCause && props.analysisData.possibleRootCauseSoFar && (
          <>
            <Card.Title>Analysis Summary, so far</Card.Title>
            <Card.Text>{props.analysisData.possibleRootCauseSoFar}</Card.Text>
            </>)}
        </>
        ) : (
          <>
            <Card.Title>Analysis in Progress</Card.Title>
            <Card.Text>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Card.Text>
          </>
        )}
      </Card.Body>
    </Card>

    <AnalysisSteps analysisData={props.analysisData} />
    {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
      <div className="flex-grow-1">
        {!isInterrupting && props.analysisData.stillRunning && (
          <div className="d-flex align-items-center">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2">Relvy working its magic....</span>
          </div>
        )}
      </div>
      <button 
        className="btn btn-outline-danger ms-3" 
        onClick={handleInterrupt}
        disabled={isInterrupting}
      >
        {isInterrupting ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Stopping...
          </>
        ) : (
          'Stop Analysis'
        )}
      </button>
    </div>)}
    {false && !props.analysisData.stillRunning && (
      <div class="card">
        <div class="row mx-3">
          <div class="col-md-11">
            <input 
              type="text" 
              placeholder="Ask Relvy to look at other logs or metrics..." 
              className="form-control" 
              id="alsoLookAt" 
              value={userHint} 
              onChange={(e) => setUserHint(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div class="col-md-1">
            <button 
              type="submit" 
              className="btn btn-primary custom-btn m-0"
              style={{
                backgroundColor: '#5935c9',
                borderColor: '#5935c9',
                color: 'white',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onClick={handlePrompt}
              disabled={isPrompting}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )}
  </div>);
};

const MainContent = ({ issue, analysisData, onUserPrompt, onInterrupt }) => {
  return (
    <div>
      <AnalysisTab issue={issue} analysisData={analysisData} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} />
    </div>
  );
};


const IssueDetailsPage = (props) => {
  const [issueData, setIssueData] = useState({
    summary: props.item.summary,
    details: props.item.description,
    relevant_files: null,
  });
  const [analysisData, setAnalysisData] = useState(null);

  const [failureMessage, setFailureMessage] = useState(null);
  const [pollerId, setPollerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPollRequests, setNumPollRequests] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const processIssueData = (data) => {
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));
    processAnalysisData(data['analysis_results']);
  };

  const fetchIssueData = async (issueId) => {
    
    if (numPollRequests > 100) {
      return;
    }
    setNumPollRequests(numPollRequests + 1);
    const url = `${API_BASE_URL}/api/dashboard/v2/issues/${issueId}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const clearPollerIfExists = () => {
    clearInterval(pollerId);
  };

  useEffect(() => {
    clearPollerIfExists();
    setNumPollRequests(0);
    fetchIssueData(props.item.id);
    let newPollerId = setInterval(() => {
      fetchIssueData(props.item.id);
    }, 2000);
    setPollerId(newPollerId);

    return () => {
      clearInterval(newPollerId);
    }
  }, [props.item.id]);


  const getReadableNextStep = (nextStep, params) => {
    if (nextStep === 'request_logs') {
      return "Request logs from " + params["service"];
    } else if (nextStep === 'request_metrics') {
      return "Request metrics from " + params["service"] + ' - ' + params["metric_name"];
    } else if (nextStep === 'escalate') {
      return "Escalate to " + params['to'];
    } else {
      return nextStep;
    }
  }

  useEffect(() => {
    if (analysisData && !analysisData.stillRunning) {
      clearPollerIfExists();
    }
  }, [analysisData]);


  const processAnalysisData = (data) => {
    var output = {};      
    
    let finalResponse = data["final_response"];
    output['stillRunning'] = data["state"] !== "COMPLETED";
    if (finalResponse && "next_step" in finalResponse) {
      output['nextStep'] = getReadableNextStep(finalResponse["next_step"], finalResponse["params"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
      //output['stillRunning'] = false;
    }

    output['possibleRootCauseSoFar'] = null;

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let analysisStepType = data["analysis_history"][stepIndx].step_type;
      let analysisStepData = data["analysis_history"][stepIndx].step_data;
      //console.log(analysisStepType);
      if (analysisStepType === "next_step") {
        output['possibleRootCauseSoFar'] = analysisStepData["possible_root_cause_so_far"];
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation"){
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      } else if (analysisStepType === "user_input") {
        displayAnalysisSteps.push({
          'step_type': 'user_input',
          'message': analysisStepData['message'],
        });
      }
    }

    if (output['stillRunning'] && data["analysis_history"].length > 0 && data['analysis_history'][data["analysis_history"].length - 1].step_type === 'next_step') {
      displayAnalysisSteps.push({
        'step_type': 'ongoing',
        'currentNextStep': displayNextStep,
        'currentNextStepReason': displayNextStepReason,
      });
    }

    output['analysisSteps'] = displayAnalysisSteps;
    setAnalysisData(output);
  };

  const onInterrupt = () => {
    return fetch(`${API_BASE_URL}/api/dashboard/v2/issues/${props.item.id}/interrupt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        return Promise.resolve();
      } else {
        console.log("Failed to interrupt analysis");
        return Promise.reject("Failed to interrupt analysis");
      }
    });
  };

  const onUserPrompt = (message) => {
    clearPollerIfExists();

    let updatedAnalysisData = analysisData;
    updatedAnalysisData['analysisSteps'].push({
      'step_type': 'user_input',
      'message': message,
    });
    setAnalysisData(updatedAnalysisData);

    fetch(`${API_BASE_URL}/api/dashboard/v2/issues/${props.item.id}/prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
      }),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
      } else {
        console.log("Failed to submit message");
      }
    });
  };

  return (
    <MainContent issue={issueData} analysisData={analysisData} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} />
  );
};

export default IssueDetailsPage;
