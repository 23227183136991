import { GoogleLogin } from '@react-oauth/google';

const Landing = (props) => {
  const onSuccessfulLogin = (credentialResponse) => {
    document.cookie = 'gloginInfo=' + JSON.stringify(credentialResponse)
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

    fetch(`${API_BASE_URL}/google/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id_token': credentialResponse.credential,
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (props.onSuccessCallback){
        props.onSuccessCallback(data);
      } else {
        window.location.href = '/configure';
      }
    });
  };

  return (
    <div className="container d-flex flex-column" style={{ minHeight: '90vh' }}>
      <div className="row flex-grow-1 align-items-center justify-content-center">
        <div className="col-md-4 col-sm-10 text-center">
        <img src="/RelvyAI_JustLogo.png" alt="Relvy Logo" className="img-fluid mb-4" style={{ maxWidth: '100px' }} />
        <h3 className="mb-3">Welcome to Relvy</h3>
          <div className="card p-3 shadow-sm mb-4">
            <p className="mb-3" style={{ fontSize: '0.8rem' }}>Start using Relvy by Signing in with your Google Account</p>
            <div className="d-flex justify-content-center align-items-center">
              <GoogleLogin 
                onSuccess={onSuccessfulLogin}
                onError={() => {
                  console.log('Login Failed');
                }}
                render={({ onClick, disabled }) => (
                  <button 
                    className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center"
                    onClick={onClick}
                    disabled={disabled}
                    style={{minHeight: '40px'}}
                  >
                    <img src="/google-logo.png" alt="Google logo" className="me-2" style={{width: '20px', height: '20px'}} />
                    Sign in with Google
                  </button>
                )}
              />
            </div>
          </div>
          <hr className="w-100 mx-auto mb-3" />
          <div className="explore-section">
            <h5 className="mb-3">Explore Relvy</h5>
            <div className="d-grid gap-2 col-8 mx-auto">
              <a href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij" className="btn btn-sm btn-outline-primary" target="_blank" rel="noreferrer">
                Self-host Relvy (Enterprise)
              </a>
              <a href="https://sandbox.relvy.ai" className="btn btn-sm btn-outline-secondary" target="_blank" rel="noreferrer">
                Try Sandbox Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-auto py-3">
        <div className="text-center">
          <small>
            <a href="https://www.relvy.ai/privacy-policy" className="text-muted me-2" target="_blank" rel="noreferrer">Privacy Policy</a>
            |
            <a href="https://www.relvy.ai/relvy-tos" className="text-muted ms-2" target="_blank" rel="noreferrer">Terms of Service</a>
          </small>
        </div>
      </footer>
    </div>
  );
};

export default Landing;

