import { GoogleLogin } from '@react-oauth/google';

const LoginModule = (props) => {
  const onSuccessfulLogin = (credentialResponse) => {
    // Add to cookie
    document.cookie = 'gloginInfo=' + JSON.stringify(credentialResponse)

    fetch('/google/signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id_token': credentialResponse.credential,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        props.onSuccessCallback(data);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-12 offset-md-4">
            <GoogleLogin onSuccess={onSuccessfulLogin}
              onError={() => {
                console.log('Login Failed');
              }}
              useOneTap={true}
              auto_select={true}
            />
        </div>
      </div>
    </div>
  );
};


export default LoginModule;
