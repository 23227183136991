/*global chrome*/
import { useEffect, useState, useRef, createRef } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Container = () => {
  const [alerts, setAlerts] = useState([])
  const [selectedAlert, setSelectedAlert] = useState(0)

  const fetchAlerts = () => {
    fetch("/api/alerts")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setAlerts(data);
      })
  }

  const selectListItemHandler = (index) => {
    setSelectedAlert(index);
  }

  useEffect(() => {
    fetchAlerts()
    setSelectedAlert(0);
  }, [])

  return (
    <div class="container">
        <div class="row">
          <div class="col-md-3">
            <ListView items={alerts} selectedAlert={selectedAlert} selectHandler={selectListItemHandler}/>
          </div>
          <div class="col-md-9">
            {alerts[selectedAlert] !== undefined ? <DetailView item={alerts[selectedAlert]} refreshCallback={fetchAlerts} /> : ''}
          </div>
        </div>
      </div>
  );
}

const ListView = (props) => {

  return (
    props.items.map((item, index) => <ListItemView item={item} indx={index} selectedIndx={props.selectedAlert} selectHandler={props.selectHandler} />)
  );
}

const ListItemView = (props) => {

  const getAlertTitle = () => {
    return props.item.payload.incident["policy_name"];
  }

  const getAlertSummary = () => {
    return props.item.payload.incident["summary"];
  }

  return (
    <div onClick={ () => { props.selectHandler(props.indx) }} className={"p-3" + (props.selectedIndx === props.indx ? " shadow" : "")} style={props.selectedIndx === props.indx ? {} : {cursor: "pointer"}}><div class="row text-break">
      <h3>{getAlertTitle()}</h3>
      <div className={"row text-break" + (props.selectedIndx === props.indx ? "" : " text-truncate")} style={props.selectedIndx === props.indx ? {} : {height: '50px'}}>
        <p>{getAlertSummary()}</p>
      </div>
      </div></div>
  );
}

const PrimaryMetricView = (props) => {

  const [metricData, setMetricData] = useState(undefined);

  const getMetricData = (alertData) => {
    let primaryMetric = alertData['primary_metric']['response'][0]['point_data'].map((item) => {
      return {
        x: (new Date(item['time_interval']['start_time'])),
        y: item['values'][0]['double_value']
      }
    }).reverse();
    return {
      datasets: [{
        label: alertData['payload']['incident']['metric']['displayName'],
        data: primaryMetric,
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(135,206,250, 0.5)',
      }, {
        label: 'threshold',
        data: primaryMetric.map((item) => {
          return {
            x: item['x'],
            y: alertData['payload']['incident']['condition']['conditionThreshold']['thresholdValue']
          }
        }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }]
    }
  };

  useEffect(() => {
    setMetricData(getMetricData(props.item));
  }, [props.item]);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: props.item.payload.incident["policy_name"]
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute'
        },
        ticks: {
          source: 'data'
        },
      }
    },
  };

  return (
    <div>
    {metricData===undefined ? '' : <Line options={options} data={metricData} />}
  </div>
  );
};

const RootCauseView = (props) => {

  return (
    <div class="text-wrap border border-2 rounded" style={{'height': '300px', 'overflow-y': 'auto'}}>
      <h4 class="mx-3 pt-3">Root Cause</h4>
      { props.loading ? <p class="mx-3">Loading...</p> : (<div>
      <p class="mx-3 mb-0 text-muted"><span>Service: </span><span>{'analysis_summary' in props.item ? props.item['analysis_summary']['rootCauseService']['name'] : ""}</span></p>
      <p class="mx-3 text-muted"><span>Oncall: </span><span>{'analysis_summary' in props.item ? props.item['analysis_summary']['rootCauseService']['oncall'] : ""}</span></p>
      <p class="mx-3 text-muted">{'analysis_summary' in props.item ? props.item['analysis_summary']['details'] : ""}</p>
      <p class="mx-3"><a href="#root-cause-details">See relevant logs and metrics</a></p></div>)}
    </div>
  );
};

const IncidentImpactView = (props) => {
  const getImpactStyles = () => {
    if ('impact_analysis' in props.item) {
      switch (props.item['impact_analysis']['category']) {
        case 'critical':
          return {'color': 'red'};
        case 'major':
          return {'color': 'orange'};
        case 'minor':
          return {'color': 'yellow'};
        case 'resolved':
          return {'color': 'green'};
        default:
          return {'color': 'black'};
      }
    }
    return {}
  };

  const [stillLoading, setStillLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setStillLoading(false);
    }, 2000);
  }, []);

  return (
    <div class="text-wrap border border-2 rounded" style={{'height': '300px', 'overflow-y': 'auto'}}>
      <h4 class="mx-3 pt-3">Impact</h4>
      {stillLoading ? <p class="mx-3">Loading...</p> : (<div>

      <p class="mx-3" style={getImpactStyles()}>{'impact_analysis' in props.item ? props.item['impact_analysis']['category'] : "Unknown"}</p>
      <p class="mx-3 text-muted">{'impact_analysis' in props.item ? props.item['impact_analysis']['title'] : ''}</p>
      <p class="mx-3"><a href="#incident-impact-details">Details</a></p></div>) }
    </div>
  );
};

const IncidentImpactDetailsView = (props) => {
  return (
    <div class="m-3" id="incident-impact-details">
      <h4>Impact Assessment Data</h4>
    </div>
  );
};

const AnalysisStepView = (props) => {
  return (
    <div class="my-3">
      {props.item.type === 'log' ? <LogAnalysisStepView item={props.item} /> : 
      (props.item.type === 'check_service_status' ? <CheckServiceStatusStepView item={props.item} /> : '')}
      <hr />
    </div>
    );
};

const CheckServiceStatusStepView = (props) => {

  const [analysisDetails, setAnalysisDetails] = useState('');
  useEffect(() => {
    if ('analysis' in props.item && props.item.analysis !== null) {
      setAnalysisDetails(props.item.analysis[0]['details']);
    }
  }, [props.item]);

  const getAnalysisDetailsStyle = () => {
    if (analysisDetails.includes('not')) {
      return {'color': 'red'};
    } else {
      return {'color': 'green'};
    }
  };

  return (
    <div>
        <p class="my-3">
          <span>Step {props.item['step_index']}: {props.item['query_reason']}</span>
        </p>
        <div class="row my-4">
          <p><span class="text-muted">Result: </span><span style={getAnalysisDetailsStyle()}>{analysisDetails}</span></p>
        </div>
    </div>
  );
};

const LogAnalysisStepView = (props) => {
  const refs = useRef([]);

  const [logLines, setLogLines] = useState([]);
  useEffect(() => {
    setLogLines(getLogLines());
  }, [props.item]);

  refs.current = props.item.response.map((_, i) => refs.current[i] || createRef());

  const [analysisDetails, setAnalysisDetails] = useState('');
  useEffect(() => {
    if ('analysis' in props.item && props.item.analysis !== null) {
      setAnalysisDetails(props.item.analysis[0]['details']);
    }
  }, [props.item]);

  const getLogLines = () => {
    let analysisStep = (('analysis' in props.item && props.item.analysis !== null) ? props.item.analysis[0] : undefined);
    var timestamp;
    let ll = props.item.response.map((item, index) => {
      try {
        item = JSON.parse(item);
      } catch (e) {
      }

      var str_rep;
      if (typeof(item) === 'string') {
        str_rep = item;
      } else {
        str_rep = item['message'];
        timestamp = item['timestamp'];
      }

      return {
        message: str_rep,
        lineNum: index,
        highlight: false,
        timestamp: timestamp,
        expanded: false,
      }
    });

    var max_js = 0;
    var max_match_indices = [];
    let words_in_message = new Set(analysisStep['log_message'].split(/\s+/));
    for (var i = 0; i < ll.length; i++) {
      if (ll[i].message === undefined) {
        continue;
      }
      let words_in_key = ll[i].message.split(/\s+/);
      let jaccard = new Set(words_in_key.filter(x => words_in_message.has(x))).size / new Set(words_in_key.concat(words_in_message)).size;
      if (jaccard > max_js) {
        max_js = jaccard;
        max_match_indices = [i];
      } else if (jaccard === max_js) {
        max_match_indices.push(i);
      }
    }

    for (var i = 0; i < max_match_indices.length; i++) {
      ll[max_match_indices[i]].highlight = true;
    }
    return ll;
  };

  const divRef = useRef(null);

  useEffect(() => {
    for (var i = 0; i < logLines.length; i++) {
      if (logLines[i].highlight) {
        divRef.current.scrollTop = refs.current[logLines[i].lineNum].current.offsetTop - refs.current[0].current.offsetTop - 100;
        break;
      }
    }
  }, [logLines]);

  const getLineStyles = (item) => {
    var mp = {
      'overflow': 'hidden',
      'cursor': 'pointer',
    }
    if (!item.expanded){
      mp['height'] = '30px';
    }
    if (item.highlight) {
      mp['background'] = 'yellow';
    }
    return mp
  }

  return (
    <div>
      <p class="my-3">
        <span>Step {props.item['step_index']}: {props.item['query_reason']}</span>
      </p>
      <div class="row my-3">
        <p class="text-muted">Relevant Logs</p>
      </div>
      <div ref={divRef} style={{height: "400px", "overflow-y": "scroll"}}>
        {logLines.map((item, indx) => {
          return (
        <div ref={refs.current[indx]} class="row py-1 text-nowrap border" style={getLineStyles(item)} onClick={() => {item.expanded = !item.expanded; setLogLines(logLines.slice());}}>
          <div class="col-md-2">
            <pre>{item['timestamp']}</pre>
          </div>
          <div class="col-md-10">
            <pre>{item['message']}</pre>
          </div>
        </div>
        );})}
      </div>
      <div class="row my-4">
        <p><span class="text-muted">Analysis: </span><span>{analysisDetails}</span></p>
      </div>
    </div>
  );
};

const RootCauseDetailsView = (props) => {
  return (
    <div id="root-cause-details" class="m-3">
      <h4>Root Cause Analysis</h4>
      { props.loading ? <div>Loading...</div> : (
      <div>
        {props.item['analysis_steps'].map((item, indx) => {
          return (
            <AnalysisStepView item={item} />
          );})}
      </div>)}
    </div>
  );
};


const ExpertRunbookView = (props) => {
  return (
    <div id="expert-steps" class="m-3">
      <h4>Your Debug Steps</h4>
      { props.item['expert_steps'].map((item, indx) => {
        return (
        <div class="row">
          <p><span>Step {item['step_index']}: </span><span class="text-muted">{item['event_payload']['humanized_step']}</span>(<a target="_blank" rel="noreferrer" href={item['event_payload']['url']}>link</a>)</p>
        </div>
        );})}
    </div>
   );
};


const DetailView = (props) => {

  const rootCauseAvailable = () => {
    return 'analysis_summary' in props.item;
  }

  const [loadingRootCause, setLoadingRootCause] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingRootCause(false);
    }, 5000);
  }, []);

  const [loadingImpactView, setLoadingImpactView] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingImpactView(false);
    }, 2000);
  }, []);

  return (
    <div>
      <div class="row">
        <div>
          {props.item === undefined ? '' : <PrimaryMetricView item={props.item} />}
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-4">
          {props.item === undefined ? '' : <IncidentImpactView loading={loadingImpactView} item={props.item} />}
        </div>
        <div class="col-md-4">
          {(props.item === undefined || !rootCauseAvailable()) ? '' : <RootCauseView loading={loadingRootCause} item={props.item} />}
        </div>
        <div class="col-md-4">
          {props.item === undefined ? '' : <DebugView item={props.item} refreshCallback={props.refreshCallback} />}
        </div>
      </div>
      <div class="row">
        <IncidentImpactDetailsView loading={loadingImpactView} item={props.item} />
      </div>
      <div class="row">
        <RootCauseDetailsView loading={loadingRootCause} item={props.item} />
      </div>
      { props.item['expert_steps'].length > 0 ? 
      <div class="row">
        <ExpertRunbookView item={props.item} />
      </div> : '' }
    </div>
   );
}

const DebugView = (props) => {

  const [inSession, setInSession] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(false);
  }, [props.item]);

  const extensionId = 'dbhnpdmnighbegopbnkjijbmmndmmpkk';

  function onStart(){
    if (!inSession){
      chrome.runtime.sendMessage(extensionId, {'event': 'start_recording', alertId: props.item.id});
      setInSession(true);
    } else {
      chrome.runtime.sendMessage(extensionId, {'event': 'stop_recording', alertId: props.item.id});
      setInSession(false);
      setIsProcessing(true);
      setTimeout(() => {
        props.refreshCallback();
      }, 10000);
    }
  }

  return (
    <div class="text-wrap border border-2 rounded" style={{'height': '300px', 'overflow-y': 'auto'}}>
      <h4 class="mx-3 pt-3">Debug</h4>
      { isProcessing ? <div><p class="text-muted mx-3">Processing debug session...</p></div> : 
        <div>
          { props.item['expert_steps'].length > 0 ? <div>
            <p class="text-muted mx-3">We have created a runbook for you based on your debug steps</p>
            <p class="mx-3"><a href="#expert-steps">View</a></p>
          </div> : ''}
          { props.item['expert_steps'].length === 0 ? <div>
            <p class="text-muted mx-3">We can keep track of your debug steps and attach it to this incident. Future you will thank you!</p>
            <button type="button" class="btn btn-link mx-3 p-0" id="startRecording" onClick={onStart}>{inSession ? "Stop" : "Start"} Debug Session</button>
          </div> : ''}
        </div>
        }
    </div>
  );
}

function Dashboard() {
  return (
    <Container />
  );
}

export default Dashboard;
