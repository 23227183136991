import { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { IoAddCircle } from 'react-icons/io5';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import IssueDetailsPage from '../components/issue_details';
import LoginModule from '../components/glogin';

import '../components/demo.css';
import {getLoginInfoFromCookie} from '../routes/utils';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CreateNewIssueView = (props) => {
  const [issueSummary, setIssueSummary] = useState('');

  return (
    <div className="m-3">
      <h3>Describe the issue you are seeing</h3>
      <Form onSubmit={(e) => { e.preventDefault(); props.onSubmit(issueSummary); }}>
        <div className="d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="users are unable to sign in"
            value={issueSummary}
            onChange={(e) => setIssueSummary(e.target.value)}
            className="me-2"
          />
          <Button
            variant="success"
            type="submit"
          >
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}

const ListView = (props) => {
  return (
    <div className="list-group list-group-flush">
      {props.items.map((item, index) => (
        <ListItemView key={index} item={item} indx={index} selectedIndx={props.selectedIssue} selectHandler={props.selectHandler} />
      ))}
    </div>
  );
}

const ListItemView = (props) => {
  const getIssueSummary = () => {
    return props.item.summary;
  }

  return (
    <div 
      onClick={() => { props.selectHandler(props.indx) }}
      className={`list-group-item list-group-item-action ${props.selectedIndx === props.indx ? "active" : ""}`}
      style={props.selectedIndx === props.indx ? {backgroundColor: '#f8f9fa', borderColor: '#e9ecef', color: '#5935c9', cursor: "default"} : {cursor: "pointer"}}
    >
      <div className="d-flex w-100 justify-content-between">
        <h6 className="mb-1">{getIssueSummary().substring(0, 80)}{getIssueSummary().length > 50 ? '...' : ''}</h6>
        <small>{props.item.created_at}</small>
      </div>
      <p className="mb-1 text-truncate">{props.item.description}</p>
    </div>
  );
}

const DetailView = (props) => {
  return (
    <div>
      <IssueDetailsPage item={props.item} />
    </div>
   );
}

const Container = () => {
  const [loginState, setLoginState] = useState(null);

  const [clientSystemId, setClientSystemId] = useState(null);
  const [issues, setIssues] = useState([])
  const [selectedIssue, setSelectedIssue] = useState(null) // null means creating new issue

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const fetchIssues = () => {
    if (!clientSystemId) {
      return;
    }

    return fetch(`${API_BASE_URL}/api/dashboard/v2?client_system_id=${clientSystemId}`)
      .then(response => response.json())
      .then(data => {
        setIssues(data['issues']);
        setSelectedIssue(null);
        return data['issues'];
      });
  }

  const selectListItemHandler = (index) => {
    setSelectedIssue(index);
  }

  useEffect(() => {
    fetchIssues()
  }, [clientSystemId])

  const onCancelCreateNew = () => {
    setSelectedIssue(issues.length > 0 ? 0 : null);
  }

  const onSubmitNew = (issueSummary) => {
    fetch(`${API_BASE_URL}/api/dashboard/v2/issues`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client_system_id: clientSystemId,
        summary: issueSummary
      })
    })
    .then(() => fetchIssues())
    .then((updatedIssues) => {
      setSelectedIssue(0);  // Select the newest issue
    });
  }

  const loginCallback = (data) => {
    setClientSystemId(data.user.workspaces[0].client_system_id);
    setLoginState('logged_in');
  }

  const loginFailureCallback = () => {
    setLoginState('not_logged_in');
  }  

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  if (!loginState) {
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in'){
    return <LoginModule onSuccessCallback={loginCallback} />;
  }

  return (
    <div className="container mt-4">
      <div className="row">
        {/* Left panel */}
        <div className="col-md-3 position-sticky" style={{ top: '60px', minHeight: '50vh', height: '100vh', overflowY: 'auto' }}>
          <div className="card">
            <div className="card-body">
              <button 
                type="button" 
                className="btn btn-outline-primary edit-config-btn w-100 mb-3" 
                onClick={() => { window.location.href = "/configure" }}
                style={{
                  color: '#5935c9', 
                  borderColor: '#5935c9',
                  transition: 'background-color 0.3s, color 0.3s'
                }}
              >
                Edit Configuration
              </button>
              <div className="d-flex justify-content-between align-items-center mb-2 bg-light p-2">
                <h5 className="mb-0">Issues</h5>
                <Button 
                  variant="link" 
                  className="p-0 d-flex align-items-center justify-content-center" 
                  style={{ width: '32px', height: '32px' }}
                  onClick={() => setSelectedIssue(null)}
                >
                  <IoAddCircle size={28} color="green" />
                </Button>
              </div>
              <ListView items={issues} selectedIssue={selectedIssue} selectHandler={selectListItemHandler}/>
            </div>
          </div>
        </div>
        
        {/* Right panel */}
        <div className="col-md-9">
          {selectedIssue === null ? (
            <CreateNewIssueView onCancel={onCancelCreateNew} onSubmit={onSubmitNew} />
          ) : (
            issues[selectedIssue] !== undefined && (
              <DetailView item={issues[selectedIssue]} refreshCallback={fetchIssues} />
            )
          )}
        </div>
      </div>
    </div>
  );
}

function DashboardV2() {
  return (
    <Container />
  );
}

export default DashboardV2;
